import React from "react";
import PropTypes from "prop-types";
import "../styles/landing/button.scss";

function Button({ className, href, target, rel, ariaLabel, label }) {
  return (
    <a
      className={`button ${className}`}
      href={href}
      target={target}
      rel={rel}
      aria-label={ariaLabel}
    >
      {label}
    </a>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

Button.defaultProps = {
  className: "",
  target: "_self",
  rel: "",
};

export default Button;
