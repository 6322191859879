import React from "react";
import { Box } from "@primer/react";
import PrivateRepoIcon from "../../../assets/images/blankslate/ic_private_repos.png";

export const RepositorySelectionBlankslate = () => {
  return (
    <div
      style={{
        display: "grid",
        justifyItems: "center",
        padding: 32,
        blockSize: "80%",
        inlineSize: "40%",
        margin: "0 auto",
        maxWidth: "485px",
        width: "100%",
      }}
    >
      <img
        src={PrivateRepoIcon}
        alt="Private Repository Icon"
        style={{
          width: 64,
          height: 64,
        }}
      />
      <Box
        style={{
          margin: 0,
          marginBottom: -10,
        }}
      >
        <h2>No Repository Selected!</h2>
      </Box>
      <p
        style={{
          margin: 0,
          marginBottom: 1,
        }}
      >
        Select which repositories you want to use in CodeFlow.
      </p>
    </div>
  );
};
