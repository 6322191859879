import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import GithubButton from "./components/GithubButton";
import "./styles/RegistrationPage.scss";
import Button from "../../components/IntroButton";

const githubOAuthURL = `https://github.com/login/oauth/authorize?client_id=5674407aaab72f1cc405&scope=read:user%20user:email%20repo`;

const RegistrationPage = () => {
  const [email, setEmail] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const interBubbleRef = useRef(null);
  const requestRef = useRef();
  const curX = useRef(0);
  const curY = useRef(0);
  let tgX = 0;
  let tgY = 0;

  const move = () => {
    curX.current += (tgX - curX.current) / 20;
    curY.current += (tgY - curY.current) / 20;

    if (interBubbleRef.current) {
      interBubbleRef.current.style.transform = `translate(${Math.round(
        curX.current
      )}px, ${Math.round(curY.current)}px)`;
    }

    requestRef.current = requestAnimationFrame(move);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted with email:", email);
  };

  const fetchGitHubToken = async (code) => {
    if (!code) {
      throw new Error("Code parameter is missing");
    }

    const response = await fetch(`http://localhost:3000/api/github-token`, {
      method: "POST",
      body: JSON.stringify({ code }),
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }
    return response.json();
  };

  // Extract code from URL
  const queryString = window.location.search;
  const code = new URLSearchParams(queryString).get("code");

  // Use useQuery hook
  const { data, isLoading, error } = useQuery(
    ["githubToken", code],
    () => fetchGitHubToken(code),
    {
      enabled: !!code,
      retry: false,
    }
  );

  useEffect(() => {
    const handleMouseMove = (event) => {
      tgX = event.clientX;
      tgY = event.clientY;
    };

    window.addEventListener("mousemove", handleMouseMove);
    move();

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, []);

  return (
    <div>
      <div className="registration-page">
        <div className="right-side">
          <div className="registration-container">
            <div className="main-content">
              <section className="account-creation">
                <h1>Create an account</h1>
                <form onSubmit={handleSubmit}>
                  <div className="custom-input">
                    <label>
                      <input
                        type="name"
                        name="name"
                        placeholder="Fullname"
                        aria-required="true"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </label>
                    <div className="custom-input__error">
                      Fullname required!
                    </div>
                  </div>
                  <div className="custom-input">
                    <label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        aria-required="true"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="custom-input">
                    <label>
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        aria-required="true"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </label>
                    <div className="custom-input__error">
                      Invalid email address
                    </div>
                  </div>
                  <Button
                    className="button--primary"
                    href="https://www.figma.com/file/JrZLsfCdfOnmqEJqJyhiXH/Module-2.1*?type=design&node-id=1739%3A35118&mode=design&t=hwECeoUuxQLnG8eE-1"
                    rel="nofollow noopener noreferrer"
                    aria-label="Sign Up"
                    label="Sign Up"
                  />
                </form>
                <div className="alternative-auth">
                  <div className="divider">
                    <span className="divider-text">OR</span>
                  </div>
                  {/** TODO: Move to enviroment variable */}
                  <GithubButton
                    label="Sign up with GitHub"
                    onClick={() => window.open(githubOAuthURL)}
                  />
                </div>
                <p className="terms">
                  By clicking continue, you agree to our Terms of Service and
                  Privacy Policy.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <div className="gradient-bg">
        <svg xmlns="http://www.w3.org/2000/svg">
          <defs>
            <filter id="goo">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="10"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                result="goo"
              />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
        <div className="gradients-container">
          <div className="g1" />
          <div className="g2" />
          <div className="g3" />
          <div className="g4" />
          <div className="g5" />
          <div className="interactive" />
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;
