import React, { useEffect, useState, useRef } from "react";
import GAJobItem from "../../components/core/GitHubActions/GAJobItem";
import GitHubActionItem from "../../components/core/GitHubActions/GitHubActionItem";
import Action from "../../components/core/GitHubActions/Action";
import { RepositorySelectionBlankslate } from "../../components/core/Blanslate/Blankslate";
import DraggableAction from "../../components/core/GitHubActions/DraggableAction";
import { capitalize } from "../../utils/StringUtils";
import axios from "axios";

const CodeFlowContent = ({
  isPropertiesBarVisible,
  selectedRepo,
  selectedGithubWorkflow,
  selectedGithubActionStep,
  selectedJobId,
  showActionDetails,
  handleGitHubWorkflowClick,
  handleGitHubActionStepClick,
  handleOpenAction,
  workflows,
  onActionContentUpdate,
  selectedActionStepData,
}) => {
  const [steps, setSteps] = useState([]);
  const [showJobs, setShowJobs] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [lines, setLines] = useState([]);
  const [selectedJob, setSelectedJob] = [""];
  const parentRef = useRef(null);

  // Find the selected workflow from the list of workflows
  const selectedWorkflow = workflows.find(
    (workflow) => selectedGithubWorkflow === workflow.name
  );

  // Effect hook to fetch repository workflow information
  useEffect(() => {
    let didCancel = false;

    // Async function to fetch workflow details from an API
    const fetchRepositoryWorkflowInfo = async () => {
      // Guard clause to exit early if required data is missing
      if (!selectedRepo || !selectedGithubWorkflow) return;

      try {
        // Fetch data using axios
        const response = await axios.get(
          `http://localhost:3000/api/repositories/workflows/content/${selectedRepo.author}/${selectedRepo.name}/${selectedWorkflow.name}/info`,
          { withCredentials: true }
        );

        // Process response if the component is still mounted and data is available
        if (!didCancel && response.data && response.data.jobs) {
          // Convert jobs object to an array and add a key property
          const jobsArray = Object.entries(
            response.data.jobs
          ).map(([key, job]) => ({ ...job, key }));

          setJobs(jobsArray); // Update the jobs state

          // Flatten steps from all jobs into a single array
          let allSteps = [];
          jobsArray.forEach((job) => {
            if (job.steps) {
              allSteps.push(...job.steps);
            }
          });

          setSteps(allSteps); // Update the steps state
        }
      } catch (error) {
        console.error("Error fetching workflow content:", error);
      }
    };

    fetchRepositoryWorkflowInfo();

    // Cleanup function to set didCancel flag when the component unmounts
    return () => {
      didCancel = true;
    };
  }, [selectedRepo, selectedWorkflow]); // Dependencies for the useEffect hook

  // Effect hook to handle opening an action
  useEffect(() => {
    handleOpenAction(false);
  }, [selectedRepo]);

  const handleStepPositionChange = (id, newPosition) => {
    setSteps((prevSteps) => {
      return prevSteps.map((step) => {
        if (step.id === id) {
          return { ...step, position: newPosition };
        }
        return step;
      });
    });
  };

  // Whenever the steps state changes, update the lines
  useEffect(() => {
    if (!steps) {
      setLines([]); // Clear lines if steps are undefined
      return;
    }

    const newLines = steps
      .map((step, index) => {
        if (step && index < steps.length - 1) {
          const nextStep = steps[index + 1];
          if (
            nextStep &&
            step.ref &&
            step.ref.current &&
            nextStep.ref &&
            nextStep.ref.current
          ) {
            return {
              x1: step.position.left + step.ref.current.offsetWidth / 2,
              y1: step.position.top + step.ref.current.offsetHeight / 2,
              x2: nextStep.position.left + nextStep.ref.current.offsetWidth / 2,
              y2: nextStep.position.top + nextStep.ref.current.offsetHeight / 2,
            };
          }
        }
        return null;
      })
      .filter((line) => line !== null);

    setLines(newLines);
  }, [steps]);

  // Function to handle updates to the action information
  const handleActionInfoUpdate = (info, actionName) => {
    if (selectedGithubWorkflow === actionName) {
      onActionContentUpdate(info.content);
    }
  };

  const handleSelectedStepActionData = (data) => {
    selectedActionStepData(data);
  };

  const handleSelectedJob = (jobId) => {
    selectedJobId(jobId)
  }

  // Function to toggle the visibility of jobs
  const toggleShowJobs = () => {
    handleOpenAction(true);
    setShowJobs(jobs.length > 1 ? !showJobs : false);
  };

  return (
    <div
      className={`codeflow__content ${
        isPropertiesBarVisible ? "codeflow__content--compressed" : ""
      }`}
      ref={parentRef}
    >
      <div className="repository-content">
        {/* Conditional rendering based on the state of the application */}
        {selectedRepo && !showActionDetails ? (
          // Map through workflows to display GitHub Action items
          workflows.map((workflow) => (
            <GitHubActionItem
              key={workflow.id}
              action_name={workflow.name}
              onActionClick={() => handleGitHubWorkflowClick(workflow.name)}
              onOpenAction={toggleShowJobs}
              isSelected={selectedGithubWorkflow === workflow.name}
              onActionInfoUpdate={handleActionInfoUpdate}
              repo={selectedRepo}
              workflow={workflow}
            />
          ))
        ) : selectedRepo && showActionDetails ? (
          // Conditional rendering for jobs and steps
          jobs.length > 1 && showJobs ? (
            // Map through jobs if there are multiple jobs
            jobs.map((job, index) => {
              // Destructure and process job details
              const [osType = "Unknown OS", osVersion = "Unknown Version"] =
                job["runs-on"]?.split("-") || [];
              const timeoutMinutes = job["timeout-minutes"] || "Unknown";
              return (
                // Render job item
                <GAJobItem
                  key={index}
                  jobTitle={job.key}
                  items={[
                    {
                      name: "Operating System",
                      children: (
                        <>
                          <span className="os">{capitalize(osType)}:</span>
                          <span className="os-version">
                            {capitalize(osVersion)}
                          </span>
                        </>
                      ),
                      orientation: "horizontal",
                    },
                    {
                      name: "Timeout",
                      description: `${timeoutMinutes} minutes`,
                    },
                  ]}
                />
              );
            })
          ) : (
            <>
              {steps.map((step) => {
                const stepId = `${step.name} ${
                  step.run && !step.uses ? "Bash Script" : step.uses
                }`
                  .toLowerCase()
                  .replace(/\s+/g, "");

                const jobId =
                  jobs.length === 1 ? jobs[0].key : selectedJob || "";

                return (
                  <DraggableAction
                    id={stepId}
                    key={stepId}
                    parentRef={parentRef}
                    onPositionChange={handleStepPositionChange}
                    ref={step.ref}
                  >
                    <Action
                      id={stepId}
                      jobId={jobId}
                      step={step}
                      isSelected={selectedGithubActionStep === stepId}
                      handleActionSelection={() =>
                        handleGitHubActionStepClick(stepId)
                      }
                      selectedActionStepData={handleSelectedStepActionData}
                      selectedJobId={handleSelectedJob}
                    />
                  </DraggableAction>
                );
              })}

              <svg
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  pointerEvents: "none",
                  overflow: "visible",
                }}
              >
                {lines.map((line, index) => (
                  <line
                    key={`line-${index}`}
                    x1={line.x1}
                    y1={line.y1}
                    x2={line.x2}
                    y2={line.y2}
                    stroke="black"
                    strokeWidth="2"
                  />
                ))}
              </svg>
            </>
          )
        ) : (
          // Display a blank slate if no repository is selected
          <RepositorySelectionBlankslate />
        )}
      </div>
    </div>
  );
};

// Export the component wrapped in React.memo for performance optimization
export default React.memo(CodeFlowContent);
