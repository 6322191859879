import React from "react";
import GAJobHeader from "./GAJob/GAJobHeader";
import GAJobInfoItem from "./GAJob/GAJobInfoItem";
import Divider from "../../Divider";
import "../../../styles/core/GitHubActions/GAJobItem.css";

const GAJobItem = ({ jobTitle, items }) => {
  return (
    <div className="job">
      <GAJobHeader title={jobTitle} />
      <Divider />
      <div className="job-content">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <GAJobInfoItem
              name={item.name}
              description={item.description}
              orientation={item.orientation}
            >
              {item.children}
            </GAJobInfoItem>
            {index < items.length - 1 && (
              <Divider color="#D0D7DE" colorOpacity={0.32}/>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default GAJobItem;
