import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import Button from "../../../components/IntroButton";
import { useRemoteConfig } from "../../../providers/RemoteConfigProvider";

function IntroSection() {
  const config = useRemoteConfig();

  const headerButtons = [
    <Button
      key="1"
      className="section-header__button"
      href="/auth/register"
      ariaLabel="Get started with CodeFlow"
      label="Get Started"
    />,
  ];

  return (
    <section className="intro" data-parallax-container="">
      <div className="container">
        <SectionHeader
          title="Effortless GitHub Workflow Automation"
          description="Design, manage, and execute GitHub Actions with ease and precision — all in one place."
          buttons={ config.isProductionReady ? headerButtons : null}
        />
        <div className="intro__image-parallax">
          <div className="image-parallax" data-parallax-image data-speed="8">
            <picture>
              <source
                srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_image-Q6Qg1bDvIAR341s8cq3BCPUrN9XtYH.webp"
              />
              <source
                media="(max-width: 599px)"
                srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_image-Q6Qg1bDvIAR341s8cq3BCPUrN9XtYH.webp"
              />
              <img
                src="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_image-Q6Qg1bDvIAR341s8cq3BCPUrN9XtYH.webp"
                srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_image-Q6Qg1bDvIAR341s8cq3BCPUrN9XtYH.webp"
                width="1520"
                height="700"
                alt="image components"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IntroSection;
