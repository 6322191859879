import React, { useEffect, useRef } from "react";
import { XIcon } from "@primer/octicons-react";
import "../../../styles/core/GitHubActions/ActionItem.css";
import axios from "axios";

const Action = ({ 
  id,
  jobId,
  step,
  isSelected,
  handleActionSelection,
  selectedActionStepData,
  selectedJobId
}) => {
  const actionStepInfoCache = useRef({});

  const run = step.uses ? step.uses.split("@")[0] : '';
  const selectedClass = isSelected ? "action-component selected" : "action-component"

  useEffect(() => {
    let didCancel = false;

    const fetchSelectedStepActionFile = async () => {
      try {

        const cacheKey = `run-${run}`;

        if (actionStepInfoCache.current[cacheKey]) {
          selectedActionStepData(actionStepInfoCache.current[cacheKey]);
          selectedJobId(jobId)
          return;
        }

        const response = await axios.get(
          `http://localhost:3000/api/repository/${run}`,
          { withCredentials: true }
        );

        if (!didCancel) {
          actionStepInfoCache.current[cacheKey] = response.data;
          selectedActionStepData(response.data);
          selectedJobId(jobId)
        }
      } catch (error) {
        console.error("Error fetching actions file:", error);
      }
    };

    // Fetch data only if the component is selected
    if (isSelected) {
      fetchSelectedStepActionFile();
    }

    return () => {
      didCancel = true;
    };
  }, [isSelected, run]);

  return (
    <div id={id} className={selectedClass} onClick={() => handleActionSelection(id)}>
      <div className="action-component-header">
        <div className="action-heading">
          <div className="text">
            <a className="action-component-header-text">
            {step.name || "Unknown Step"}
            </a>
            <p className="description-text">
              {step.run && !step.uses ? "Bash Script" : step.uses}
            </p>
          </div>
          <div className="action-close">
            <XIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Action;