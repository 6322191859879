import { useState } from "react";
import axios from "axios";

const useRepositoryData = () => {
  const [repositories, setRepositories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    perPage: 100,
  });

  const fetchUserRepositories = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `http://localhost:3000/api/repositories?page=${page}`,
        { withCredentials: true },
      );

      // Filter repositories to include only those with workflows
      const reposWithWorkflows = await filterReposWithWorkflows(
        response.data.data,
      );

      setRepositories((prevRepos) => {
        const newReposWithWorkflows = [...prevRepos, ...reposWithWorkflows];
        // Assuming repo.name is unique. Adjust according to your data structure, possibly using repo.id if available.
        const uniqueRepos = Array.from(
          new Map(
            newReposWithWorkflows.map((repo) => [repo.name, repo]),
          ).values(),
        );
        return uniqueRepos;
      });
      setPagination({
        currentPage: response.data.currentPage,
        perPage: response.data.perPage,
      });
    } catch (error) {
      console.error("Error fetching repositories:", error);
    }
    setIsLoading(false);
  };

  const filterReposWithWorkflows = async (repos) => {
    const checks = await Promise.all(
      repos.map(async (repo) => {
        try {
          const workflowsResponse = await axios.get(
            `http://localhost:3000/api/repositories/workflows/${repo.owner.login}/${repo.name}`,
            { withCredentials: true },
          );
          return workflowsResponse.data.length > 0 ? repo : null;
        } catch (error) {
          console.error(`Error fetching workflows for ${repo.name}:`, error);
          return null;
        }
      }),
    );

    return checks
      .filter((repo) => repo !== null)
      .map((repo) => ({
        name: repo.name,
        author: repo.owner.login,
        description: repo.description || "No description",
        avatarSrc: repo.owner.avatar_url,
        additionalLink: repo.html_url,
        additionalText: repo.name,
        isArchived: repo.archived,
        isPrivate: repo.private,
      }));
  };

  return { repositories, pagination, fetchUserRepositories, isLoading };
};

export default useRepositoryData;
