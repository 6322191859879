import React from "react";
import MainNavItem from "./MainNavItem";
import "../../styles/landing/landing.min.css";
import { useRemoteConfig } from "../../providers/RemoteConfigProvider";

function Header() {
  const config = useRemoteConfig();

  return (
    <div class="container">
      <span class="header__logo logo" role="img">
        <a class="feedback__mail" href="/">
          CodeFlow
        </a>
      </span>
      <nav class="main-nav" data-main-nav="">
        <div class="main-nav__wrapper">
          <ul class="main-nav__list">
            {config.isProductionReady ? (
              <>
                <MainNavItem
                  text="Updates"
                  href="/whats-new"
                  className="main-nav__link--notice"
                />
                <MainNavItem
                  text="Usage Guide"
                  href="usage.html"
                  className="main-nav__link"
                />
              </>
            ) : null}
            <button
              class="header__toggle"
              type="button"
              aria-label="Menu display switch"
              aria-pressed="false"
              data-header-toggle=""
            ></button>
            <MainNavItem
              text="Contact Us"
              href="mailto:hello@codeflowapp.com"
              className="main-nav__link"
            />
          </ul>
          <div class="main-nav__overlay" data-main-nav-overlay=""></div>
        </div>
      </nav>
      {config.isProductionReady ? (
        <a
          className="button button--small header__button"
          href="/auth/register"
          aria-label="Get started with CodeFlow"
          data-scroll-link=""
          data-header-button-scroll=""
        >
          Get Started
        </a>
      ) : (
        <a
          className="button button--small header__button"
          aria-label="CodeFlow is coming soon!"
          data-scroll-link=""
          data-header-button-scroll=""
        >
          Coming Soon
        </a>
      )}
      <button
        class="header__toggle"
        type="button"
        aria-label="Menu display switch"
        aria-pressed="false"
        data-header-toggle=""
      ></button>
    </div>
  );
}

export default Header;
