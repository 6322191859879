import React from "react";
import PropTypes from "prop-types";

/**
 * VideoPlayerItem - A component for embedding a video player.
 *
 * This component renders a video player with certain default behaviors like
 * autoplay, loop, and muted sound. It's designed to be a reusable component
 * for any part of the application where video content is required.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.videoSrc - The source URL of the video to be played.
 */
function VideoPlayerItem({ videoSrc }) {
  return (
    <div className="section-video__video">
      {/* Video element with necessary attributes for autoplay, loop, and mute */}
      <video
        preload="auto"
        muted
        autoPlay
        playsInline
        loop
        src={videoSrc}
      ></video>
    </div>
  );
}

// PropTypes for type checking
VideoPlayerItem.propTypes = {
  videoSrc: PropTypes.string.isRequired,
};

export default VideoPlayerItem;