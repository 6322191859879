import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Header,
  Octicon,
  Avatar,
  IconButton,
  ActionMenu,
  AnchoredOverlay,
  Tooltip,
  ActionList,
  Label,
  Flash,
  Box,
} from "@primer/react";
import { useUserProfile } from "../../hooks/useUserProfile";
import { useUserNotifications } from "../../hooks/useUserNotifications";
import { Notifications } from "../notifications/Notifications";
import { LOCAL_STORAGE_KEYS } from "../../constants/LocalStorageConstant";
import useLocalStorage from "../../hooks/useLocalStorage";
import {
  LockIcon,
  InboxIcon,
  ChevronDownIcon,
  InfoIcon,
  XIcon,
} from "@primer/octicons-react";
import "../../styles/landing/landing.min.css";

const CodeFlowHeader = ({
  repoOwner,
  repo,
  repoIsPrivate,
  action,
  actionIsEdited,
}) => {
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);

  const userProfile = useUserProfile();
  const userNotifications = useUserNotifications();
  const [
    notificationWarningHidden,
    setNotificationWarningHidden,
  ] = useLocalStorage(LOCAL_STORAGE_KEYS.NOTIFICATION_WARNING_HIDDEN, false);

  console.log(userNotifications.userNotifications.notifications);

  return (
    <Header sx={{ backgroundColor: "white" }}>
      {!repoOwner || !repo ? (
        <Header.Item
          href="#"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            class="header__logo logo"
            role="img"
            style={{
              zIndex: 5,
              color: "black",
            }}
          >
            <a
              class="feedback__mail"
              href="/"
              style={{
                fontSize24: "24px",
                lineHeight: "36px",
                fontWeight: "600",
                letterSpacing: "-0.4px",
              }}
            >
              CodeFlow
            </a>
          </span>
        </Header.Item>
      ) : (
        <Header.Item>
          <Header.Link
            href="#"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: 2,
              fontFamily: `"SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
              color: "black",
              lineHeight: 2,
            }}
          >
            <span
              sx={{
                fontWeight: "500",
                letterSpacing: 0.6,
              }}
            >
              {!repoOwner || !repo ? "" : `${repoOwner} / `}
              <span
                sx={{
                  fontWeight: "600",
                  letterSpacing: -0.1,
                }}
              >
                {repo}
              </span>
            </span>
            {repoIsPrivate && (
              <Octicon
                icon={LockIcon}
                size={14}
                sx={{ ml: 2, color: "black" }}
              />
            )}
          </Header.Link>
        </Header.Item>
      )}
      <Header.Item
        full
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "black",
          fontFamily: `"SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif`,
          lineHeight: 2,
          letterSpacing: -0.15,
        }}
      >
        <span style={{ fontWeight: "600" }}>{action}</span>
        {actionIsEdited && (
          <ActionMenu>
            <ActionMenu.Anchor>
              <Label
                variant="severe"
                size="small"
                sx={{ ml: 2, cursor: "pointer" }}
              >
                Changes Made
                <ChevronDownIcon size={12} />
              </Label>
            </ActionMenu.Anchor>
            <ActionMenu.Overlay width="xsmall">
              <ActionList>
                <ActionList.Item
                  onSelect={() => alert("Commit with message")}
                  sx={{ fontWeight: "500" }}
                >
                  Commit with message
                </ActionList.Item>
                <ActionList.Item
                  onSelect={() => alert("Commit without message")}
                  sx={{ fontWeight: "500" }}
                >
                  Commit without message
                </ActionList.Item>
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        )}
      </Header.Item>
      <Header.Item sx={{ display: "flex", alignItems: "center", mr: 0 }}>
        <AnchoredOverlay
          open={isNotificationsOpen}
          onOpen={() => {
            setNotificationsOpen(true);
          }}
          onClose={() => {
            setNotificationsOpen(false);
          }}
          renderAnchor={(props) => (
            <IconButton
              {...props}
              icon={InboxIcon}
              size="medium"
              aria-label="Notificstions"
              sx={{ mr: 3, color: "black" }}
            />
          )}
          width="medium"
          height="medium"
        >
          <>
            {userNotifications.userNotifications.repositoriesWithoutWebhooks
              .length > 0 && !notificationWarningHidden ? (
              <Flash
                variant="warning"
                sx={{
                  margin: "5px",
                  display: "grid",
                  gridTemplateColumns: "min-content 1fr minmax(0, auto)",
                  gridTemplateRows: "min-content",
                  gridTemplateAreas: `'visual message actions close'`,
                  "@media screen and (max-width: 543.98px)": {
                    gridTemplateColumns: "min-content 1fr",
                    gridTemplateRows: "min-content min-content",
                    gridTemplateAreas: `
                  'visual message close'
                  '.      actions actions'
                `,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    paddingBlock: "8px",
                    alignSelf: "start",
                    gridArea: "visual",
                  }}
                >
                  <Octicon icon={InfoIcon} />
                </Box>
                <Box
                  sx={{
                    fontSize: "12px",
                    marginLeft: "8px",
                    lineHeight: "142.9%",
                    alignSelf: "center",
                    gridArea: "message",
                  }}
                >
                  Some repositories don’t have Webhooks to allow receiving
                  notifications on CodeFlow.
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#0071FF",
                      cursor: "pointer",
                    }}
                  >
                    Setup Webhooks
                  </p>
                </Box>
                <Box
                  sx={{
                    gridArea: "close",
                    marginLeft: "8px",
                  }}
                >
                  <IconButton
                    variant="invisible"
                    icon={XIcon}
                    aria-label="Dismiss"
                    sx={{ svg: { margin: "0", color: "fg.muted" } }}
                    onClick={() => setNotificationWarningHidden(true)}
                  />
                </Box>
              </Flash>
            ) : null}
            <Notifications
              notifications={userNotifications.userNotifications.notifications}
            />
          </>
        </AnchoredOverlay>
        <Tooltip
          text={`@${
            userProfile && userProfile.username
              ? userProfile.username
              : "Codeflow User"
          }`}
          direction="sw"
        >
          <ActionMenu>
            <ActionMenu.Anchor>
              <Avatar
                src={
                  userProfile && userProfile.avatar
                    ? userProfile.avatar
                    : "https://avatars.githubusercontent.com/u/1?v=4"
                }
                size={32}
                alt={`@${
                  userProfile && userProfile.username
                    ? userProfile.username
                    : "Codeflow User"
                }`}
                sx={{ cursor: "pointer" }}
              />
            </ActionMenu.Anchor>
            <ActionMenu.Overlay width="xsmall">
              <ActionList>
                <ActionList.Item onSelect={() => alert("Your Profile")}>
                  Your Profile
                </ActionList.Item>
                <ActionList.Item onSelect={() => alert("Upgrade")}>
                  Upgrade
                </ActionList.Item>
                <ActionList.Divider />
                <ActionList.Item
                  variant="danger"
                  onSelect={() => alert("Logout")}
                >
                  Logout
                </ActionList.Item>
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </Tooltip>
      </Header.Item>
    </Header>
  );
};

CodeFlowHeader.propTypes = {
  repoOwner: PropTypes.string,
  repo: PropTypes.string,
  repoIsPrivate: PropTypes.bool,
  action: PropTypes.string,
};

export default CodeFlowHeader;
