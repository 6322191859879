import React from "react";
import SectionHeader from "../../../components/SectionHeader";

function MainVideoSection() {
  return (
    <section className="main-video">
      <div className="container">
        <SectionHeader
          tagline="Automate with Ease"
          title="Seamless Workflow Integration"
          description="Initiate your GitHub Actions with drag-and-drop simplicity. No fuss, just efficiency."
        />
        <div className="main-video__video-wrapper">
          <div className="main-video__video">
            <video
              className="is-hidden"
              preload="auto"
              muted
              autoPlay
              playsInline
              loop
              data-src="video/figma.mp4"
              data-video=""
              src="video/figma.mp4"
            ></video>
          </div>
          <div className="main-video__recommendation">
            <svg width="20" height="20" aria-hidden="true">
              <use xlinkHref="#icon-laptop"></use>
            </svg>
            <span>Better to watch on a big screen</span>
          </div>
          <div className="main-video__description">
            {/* Description content */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainVideoSection;