import React from "react";
import SectionHeader from "../../components/SectionHeader";
import UpdatesItem from "../../components/UpdatesItem";

function WhatsNewPage() {
  return (
    <section class="updates">
      <div class="container">
        <SectionHeader
          title="CodeFlow Updates"
          description="Find out about new features and changes in CodeFlow"
        />
        <ul class="updates__list">
          <UpdatesItem
            version="Version 1.0"
            description="Launch of CodeFlow"
            tags={["CodeFlow"]}
          />
          <li class="text-card text-card--button updates__item">
            <p class="text-card__title">Don't see the new changes?</p>
            <p class="text-card__text">Contact us to reslove the issue for you</p>
            <a
              class="button button--secondary text-card__button"
              href="mailto:hello@codeflowapp.com?subject=Bug: Update not showing!"
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label="button contacts"
            >
              <svg width="24" height="24" aria-hidden="true">
                <use href="#icon-mail"></use>
              </svg>
              <span>Contact</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default WhatsNewPage;
