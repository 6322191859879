import React from "react";
import { Avatar } from "@primer/react";
import "../../styles/core/SideNavListItem.css";

const RepositoryItem = ({
  imageUrl,
  title,
  author,
  description,
  isSelected,
  onSelect,
}) => {
  const itemClass = isSelected ? "actionlist-item selected" : "actionlist-item";

  return (
    <div className={itemClass} onClick={onSelect}>
      <div className="leadingvisual-avatar">
        <Avatar src={imageUrl} size={40} alt={`Avatar for ${author}`} />
      </div>
      <div className="frame">
        <div className="group">
          <div className="list-item">{title}</div>
          <p className="div">
            <span className="text-wrapper">By </span>
            <span className="text-wrapper">{author}</span>
          </p>
        </div>
        <p className="p">{description}</p>
      </div>
    </div>
  );
};

// Set default props in case they are not provided
RepositoryItem.defaultProps = {
  imageUrl: "",
  title: "",
  author: "",
  description: "",
};

export default RepositoryItem;
