import React, { useState } from "react";
import { useDeepEffect } from "../../hooks/useDeepEffect";
import { FormControl, TextInput } from "@primer/react";
import { XIcon } from "@primer/octicons-react";

/**
 * PropertiesInputs Component
 * Dynamically generates a form based on provided input configurations.
 * Manages form state and persistence in localStorage.
 *
 * @param {Object} inputs - Configuration for input fields (name, type, etc.)
 * @param {Object} actionInfo - Additional info, used for initializing form values
 */
const PropertiesInputs = ({
  inputs,
  actionInfo,
  selectedJobId,
  selectedStepId,
}) => {
  // State for managing the values of all input fields
  const [values, setValues] = useState({});

  /**
   * Generates a unique localStorage key based on actionInfo's name.
   * The key is used for storing the entire form's data in localStorage.
   *
   * @returns {String} A unique localStorage key
   */
  const getStorageKey = () => {
    const newActionName = actionInfo.name.toLowerCase().replace(/\s+/g, "");
    return `action_${newActionName}`;
  };

  /**
   * Handles change events for input fields.
   * Updates the component state and localStorage with the new value.
   *
   * @param {Event} event - The change event from the input field
   */
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update the form field values state
    setValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };

      // Update localStorage after updating the state
      const storageKey = getStorageKey();
      const storedJson = localStorage.getItem(storageKey);
      const storedData = storedJson ? JSON.parse(storedJson) : {};

      // Assume actionInfo contains the entire structure that we want to save
      const newActionInfo = storedData; // Use storedData if available, otherwise use empty object

      if (newActionInfo && newActionInfo.jobs) {
        Object.keys(newActionInfo.jobs).forEach((jobKey) => {
          const job = newActionInfo.jobs[jobKey];

          // TODO: Remeber when uploading it to GitHub the job key needs to be removed from JSON
          job.key = jobKey;

          if (job.key === selectedJobId && job.steps) {
            for (const step of job.steps) {
              const stepId = `${step.name} ${
                step.run && !step.uses ? "Bash Script" : step.uses
              }`
                .toLowerCase()
                .replace(/\s+/g, "");

              // TODO: Remeber when uploading it to GitHub the step id needs to be removed from JSON
              step.stepId = stepId;
            }

            const stepIndex = job.steps.findIndex(
              (step) => step.stepId === selectedStepId
            );

            if (stepIndex !== -1) {
              job.steps[stepIndex].with[name] = value;
              // Log the update process
              console.log(
                `Updated job step with ID: ${selectedStepId} for field: ${name} with value: ${value}`
              );
            }
          }
        });

        // Now that newActionInfo is updated, stringify and save back to localStorage
        localStorage.setItem(storageKey, JSON.stringify(newActionInfo));
        console.log(`Updated localStorage for key: ${storageKey}`);
      }

      return updatedValues;
    });
  };

  /**
   * Clears the value of a specific input field.
   * Updates the component state and localStorage to reflect the change.
   *
   * @param {String} key - The key of the input field to clear
   */
  const handleClear = (key) => {
    setValues((prevValues) => ({ ...prevValues, [key]: "" }));
    updateLocalStorage(key, "");
  };

  /**
   * Updates or removes data in localStorage for the entire form.
   * If a field's value is cleared, its key is removed from the stored data.
   *
   * @param {String} key - The key of the data to update
   * @param {String} value - The value to set or clear
   */
  const updateLocalStorage = (key, value) => {
    const storageKey = getStorageKey();
    const storedJson = localStorage.getItem(storageKey) || "{}";
    const storedData = JSON.parse(storedJson);

    if (value === "") {
      delete storedData[key];
    } else {
      storedData[key] = value;
    }

    localStorage.setItem(storageKey, JSON.stringify(storedData));
  };

  /**
   * Custom hook to initialize and update component state based on changes in inputs or actionInfo.
   * Loads stored values from localStorage or initializes from actionInfo.
   */
  useDeepEffect(() => {
    const storageKey = getStorageKey();
    const storedJson = localStorage.getItem(storageKey);
    let initialValues = {};

    if (storedJson) {
      // Parse the stored data only if there is something in localStorage
      const storedData = JSON.parse(storedJson);
      if (storedData && storedData.jobs) {
        const job = storedData.jobs[selectedJobId];
        if (job && job.steps) {
          const step = job.steps.find((s) => s.stepId === selectedStepId);
          if (step && step.with) {
            initialValues = { ...step.with }; // Use the stored values
          }
        }
      }
    }

    // If no initialValues were set from localStorage, use the values from actionInfo
    if (
      Object.keys(initialValues).length === 0 &&
      actionInfo &&
      actionInfo.jobs
    ) {
      const job = actionInfo.jobs[selectedJobId];
      if (job && job.steps) {
        const step = job.steps.find((s) => s.stepId === selectedStepId);
        if (step && step.with) {
          initialValues = { ...step.with }; // Use the values from actionInfo
        }
      }
    }

    // Now set the form fields with either the values from localStorage or actionInfo
    setValues((inputs) => {
      return Object.keys(inputs).reduce((acc, key) => {
        acc[key] = initialValues[key] || inputs[key].default || "";
        return acc;
      }, {});
    });
  }, [actionInfo, selectedJobId, selectedStepId]);

  // Rendering the form inputs dynamically based on the inputs prop
  return (
    <>
      {Object.entries(inputs).map(([key, value]) => (
        <FormControl key={key}>
          <FormControl.Label>{key}</FormControl.Label>
          <TextInput
            className="gha-properties-input"
            aria-label={value.description}
            name={key}
            placeholder={`Example: ${value.description}`}
            autoComplete="off"
            required={value.required}
            value={values[key]}
            onChange={handleChange}
            trailingAction={
              value.required ? null : (
                <TextInput.Action
                  onClick={() => handleClear(key)}
                  icon={XIcon} // Icon for the clear action
                  aria-label="Clear input"
                  sx={{ color: "fg.subtle" }}
                />
              )
            }
          />
          <FormControl.Caption>{value.description}</FormControl.Caption>
        </FormControl>
      ))}
    </>
  );
};

export default PropertiesInputs;
