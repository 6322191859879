import React, { useState, useEffect } from "react";
import {
  TextInput,
  Button,
  CounterLabel,
  FormControl,
  Heading,
  Dialog,
  Box,
  Text,
} from "@primer/react";
import PropertiesInputs from "./PropertiesInputs";
import { XIcon } from "@primer/octicons-react";
import "../../styles/core/Propertiesbar.css";

const PropertiesBar = ({
  actionInfo,
  action_content,
  actionStepContent,
  selectedJobId,
  selectedStepId,
}) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [actionName, setActionName] = useState("");
  const [isActionStepContentChanged, setIsActionStepContentChanged] = useState(
    false
  );
  const [actionStepRuns, setActionStepRuns] = useState(null);

  const handleChange = (event) => {
    const inputActionName = event.target.value;
    setActionName(inputActionName);

    if (actionInfo && actionInfo.name) {
      const newActionName = actionInfo.name.toLowerCase().replace(/\s+/g, "");
      const storageKey = `action_${newActionName}`;
      const storedJson = localStorage.getItem(storageKey);

      if (storedJson) {
        const storedData = JSON.parse(storedJson);
        storedData.name = inputActionName;
        localStorage.setItem(storageKey, JSON.stringify(storedData));
      } else if (action_content) {
        const newData = JSON.parse(action_content);
        newData.name = inputActionName;
        localStorage.setItem(storageKey, JSON.stringify(newData));
      }
    }
  };

  useEffect(() => {
    if (actionInfo && actionInfo.name) {
      const newActionName = actionInfo.name.toLowerCase().replace(/\s+/g, "");
      const storageKey = `action_${newActionName}`;
      const storedJson = localStorage.getItem(storageKey);

      if (storedJson) {
        const storedData = JSON.parse(storedJson);
        setActionName(storedData.name);
      } else {
        setActionName(actionInfo.name);
      }
    }
  }, [actionInfo]);

  useEffect(() => {
    let parsedActionStepContent;

    // Check if actionStepContent is a string and needs parsing
    if (typeof actionStepContent === "string") {
      try {
        parsedActionStepContent = JSON.parse(actionStepContent);
      } catch (error) {
        console.error("Error parsing actionStepContent:", error);
        return; // Early return if parsing fails
      }
    } else {
      // If it's already an object, use it as is
      parsedActionStepContent = actionStepContent;
    }
    if (parsedActionStepContent && parsedActionStepContent.inputs) {
      setIsActionStepContentChanged(true);
      setActionStepRuns(parsedActionStepContent.inputs);
    } else {
      setIsActionStepContentChanged(false);
    }
  }, [actionStepContent]);

  // Handler to clear the input field
  const handleClear = () => {
    setActionName("");
  };

  // Function to open the delete confirmation dialog
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  // Function to close the delete confirmation dialog
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <div className="gha-properties">
      <div className="gha-properties-title">
        <Heading
          sx={{ fontSize: 1, mb: 2 }}
          className="gha-properties-title-heading"
        >
          Properties
        </Heading>
        <div className="gha-properties-divider" />
      </div>
      {isActionStepContentChanged ? (
        <PropertiesInputs
          inputs={actionStepRuns}
          actionInfo={actionInfo}
          selectedJobId={selectedJobId}
          selectedStepId={selectedStepId}
        />
      ) : (
        <>
          <FormControl>
            <FormControl.Label>Action ID</FormControl.Label>
            <TextInput
              className="gha-properties-input"
              aria-label="Example: add_to_project"
              name="actionId"
              placeholder="Example: add_to_project"
              autoComplete="id"
              trailingAction={
                <TextInput.Action
                  onClick={() => {
                    alert("clear input");
                  }}
                  icon={XIcon}
                  aria-label="Clear input"
                  sx={{ color: "fg.subtle" }}
                />
              }
            />
            <FormControl.Caption>
              This defines a job with the ID `build_and_lint`...
            </FormControl.Caption>
          </FormControl>
          <FormControl>
            <FormControl.Label>Action Name</FormControl.Label>
            <TextInput
              className="gha-properties-input"
              aria-label="Example: Build And Lint"
              name="Action Name"
              placeholder="Example: Build And Lint"
              autoComplete="name"
              value={actionName}
              onChange={handleChange}
              trailingAction={
                <TextInput.Action
                  onClick={handleClear}
                  icon={XIcon}
                  aria-label="Clear input"
                  sx={{ color: "fg.subtle" }}
                />
              }
            />
            <FormControl.Caption>
              This defines the name of the workflow as it will...
            </FormControl.Caption>
          </FormControl>
          <div className="gha-properties-steps">
            <FormControl.Label>Total Steps</FormControl.Label>
            <CounterLabel>{actionInfo.totalSteps}</CounterLabel>
          </div>
          <div className="gha-properties-title">
            <div className="gha-properties-divider" />
            <Heading
              sx={{ fontSize: 1, mb: 2 }}
              className="gha-properties-title-heading"
            >
              Destructive Action
            </Heading>
            <div className="gha-properties-divider" />
          </div>
          <Button
            className="gha-properties-action"
            variant="danger"
            size="medium"
            onClick={openDeleteDialog}
          >
            Delete Action
          </Button>
          <FormControl.Caption>
            Delete your GitHub action. This action is permanent and can’t be
            reversed.
          </FormControl.Caption>
          <Dialog
            isOpen={isDeleteDialogOpen}
            onDismiss={closeDeleteDialog}
            footerButtons={[
              {
                buttonType: "default",
                content: "Cancel",
                onClick: closeDeleteDialog,
              },
              {
                buttonType: "danger",
                content: "Confirm Delete",
                onClick: closeDeleteDialog,
              },
            ]}
          >
            <Dialog.Header>Delete Action</Dialog.Header>
            <Box p={3}>
              <Text>
                Are you sure you want to delete this action? This action cannot
                be undone.
              </Text>
            </Box>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default PropertiesBar;
