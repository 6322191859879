import React from "react";
import PropTypes from "prop-types";

/**
 * SectionHeader - A component for displaying a header section.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.tagline] - An optional short tagline text.
 * @param {string} props.title - The main title text.
 * @param {string} [props.description] - A brief description or supplementary text.
 * @param {Array} [props.buttons] - An array of button components to be rendered.
 */
function SectionHeader({ tagline, title, description, buttons }) {
  return (
    <div className="section-header">
      <div className="section-header__content">
        {tagline && <p className="tag section-header__tag">{tagline}</p>}
        <h1 className="section-header__title title title--h1">{title}</h1>
        {description && <p className="section-header__description">{description}</p>}

        {buttons && buttons.length > 0 && (
          <div className="section-header__buttons-group">
            {buttons.map((button, index) => (
              <React.Fragment key={index}>{button}</React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

SectionHeader.propTypes = {
  tagline: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.node),
};

SectionHeader.defaultProps = {
  tagline: "",
  description: "",
  buttons: [],
};

export default SectionHeader;