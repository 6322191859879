import React, { useState, useRef, useCallback, useEffect } from "react";
import { Flash } from "@primer/react";
import { Resizable } from "react-resizable";
import RepositoryItem from "../../components/core/RepositoryItem";
import "../../styles/core/Codeflow.css";
import "react-resizable/css/styles.css";
import SidebarLoading from "./states/SidebarLoading";
import useRepositoryData from "../../hooks/useRepositoryData";

const Sidebar = ({ selectedRepo, handleSelectRepo }) => {
  const [width, setWidth] = useState(300);
  const observer = useRef();

  const {
    repositories,
    pagination,
    fetchUserRepositories,
    isLoading,
  } = useRepositoryData();

  useEffect(() => {
    fetchUserRepositories(1);
  }, []);

  const lastRepositoryElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchUserRepositories(pagination.currentPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [pagination.currentPage, fetchUserRepositories]
  );

  const handleResize = (event, { size }) => {
    setWidth(size.width);
  };

  return (
    <Resizable width={width} onResize={handleResize}>
      <div className="codeflow__sidebar" style={{ width: `${width}px` }}>
        <div className="sidebar-header">
          <h2>Repositories</h2>
          <Flash variant="warning" sx={{ fontSize: 12 }}>
            Should you not find all of your repositories listed, please note
            that only repositories containing workflow actions are displayed
            below.
          </Flash>
        </div>
        <div className="repository-list">
          {isLoading && repositories.length === 0 ? (
            <SidebarLoading />
          ) : (
            repositories.map((repo, index) => {
              const isSecondLast = index === repositories.length - 2;
              return (
                <RepositoryItem
                  key={repo.id}
                  ref={isSecondLast ? lastRepositoryElementRef : null}
                  imageUrl={repo.avatarSrc}
                  title={repo.name}
                  author={repo.author}
                  description={repo.description}
                  isSelected={selectedRepo && selectedRepo.id === repo.id}
                  onSelect={() => handleSelectRepo(repo)}
                />
              );
            })
          )}
          {isLoading && repositories.length > 0 && <SidebarLoading />}
        </div>
      </div>
    </Resizable>
  );
};

export default Sidebar;
