import React from "react";
import { Avatar } from "@primer/react";
import "../../../styles/core/GitHubMarketplace/GMPItem.css";

export const MarketplaceItem = ({
  imageUrl,
  author,
  actionName,
  actionDescription,
}) => {
  const convertSvgToDataUrl = (svg) => {
    // Ensure that the SVG content is properly encoded
    const encodedSvg = encodeURIComponent(svg)
      .replace(/'/g, "%27") // Encode single quotes
      .replace(/"/g, "%22"); // Encode double quotes
    return `data:image/svg+xml,${encodedSvg}`;
  };
  return (
    <div className="marketplace-item">
      <Avatar src={convertSvgToDataUrl(imageUrl)} size={40} />
      <div className="marketplace-action-container">
        <div className="action-info">
          <div className="action-name">{actionName}</div>
          <p className="action-author">
            <span className="text-wrapper">By </span>
            <span className="author-name">{author}</span>
          </p>
        </div>
        <p className="action-description">{actionDescription}</p>
      </div>
    </div>
  );
};
