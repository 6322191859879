import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import WhyCodeflowFeatureItem from "../../../components/WhyCodeFlowFeatureItem";

/**
 * WhyCodeflowSection - A component for the introductory section about CodeFlow.
 *
 * This component renders a section introducing the features and benefits
 * of CodeFlow. It includes a header and a list of features, each represented
 * by the WhyCodeflowFeatureItem component.
 */
function WhyCodeflowSection() {
  return (
    <section class="module">
      <div class="container">
        {/* SectionHeader for displaying the header of this section */}
        <SectionHeader
          tagline="Made with love"
          title="Why CodeFlow?"
          description="There are a&nbsp;lot of&nbsp;important features"
        />
        <ul class="features-list module__grid">
          {/* Each feature item is represented by WhyCodeflowFeatureItem */}
          <WhyCodeflowFeatureItem
            iconHref="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_workflow_editor-1AUraepx96EpKlMEfRuovL5kgPOIZ1.png"
            title="Intuitive Workflow Editor"
            description="Craft complex workflows with an easy-to-use visual editor that simplifies every step of the process."
          />
          <WhyCodeflowFeatureItem
            iconHref="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_realtime_debugging-CEAXhNYwJO10vgjZG8o6Zde6F8QVnp.png"
            title="Real-Time Debugging (Upcoming)"
            description="Identify and fix issues on the fly with real-time feedback and debugging tools."
          />
          <WhyCodeflowFeatureItem
            iconHref="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_templates-bJ0d0EnFXwJWyMNipgpe9XNpNZhX92.png"
            title="Customizable Templates"
            description="Kickstart your automation with templates that can be customized to fit any project's needs."
          />
          <WhyCodeflowFeatureItem
            iconHref="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_integration-U6fmKv9QVnLuZzLe0V6TpyhbqUOff6.png"
            title="Seamless Integration"
            description="Flawlessly integrate with your GitHub repositories for a cohesive development experience."
          />
          <WhyCodeflowFeatureItem
            iconHref="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_security-CjCtkEXn6xhoOe19SoB6QxA6VKE5Hh.png"
            title="Advanced Security"
            description="Rest assured with top-tier security protocols keeping your automation secure at every turn."
          />
          <WhyCodeflowFeatureItem
            iconHref="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_teams-Zmgir36kKNv3WBRI8iNt3NxXrrbTjc.png"
            title="Team Collaboration (Upcoming)"
            description="Collaborate and manage GitHub Actions with your team effortlessly."
          />
        </ul>
      </div>
    </section>
  );
}

export default WhyCodeflowSection;
