import React from "react";
import PropTypes from "prop-types";

/**
 * Represents a single feature item.
 *
 * This component displays a feature with an icon, a title, and a description.
 * It's designed to be used within a list of features, where each feature item
 * can have different content based on the passed props.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.iconHref - The href for the icon used in the feature item.
 * @param {string} props.title - The title of the feature item.
 * @param {string} props.description - The description text of the feature item.
 */
function FeatureItem({ iconHref, title, description }) {
  return (
    <li className="features-item">
      {/* Icon for the feature item */}
      <div className="features-item__icon">
        <img src={iconHref} alt="Feature Icon" width="55" height="55" />
      </div>
      {/* Content of the feature item including title and description */}
      <div className="features-item__content">
        <p className="features-item__title">{title}</p>
        <p className="features-item__description">{description}</p>
      </div>
    </li>
  );
}

// PropTypes for type checking
FeatureItem.propTypes = {
  iconHref: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FeatureItem;
