import React from "react";
import PropTypes from "prop-types";

const FAQItem = ({ title, children }) => {
  return (
    <li className="text-card answers__item">
      <p className="text-card__title">{title}</p>
      <p className="text-card__text">{children}</p>
    </li>
  );
};

FAQItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FAQItem;