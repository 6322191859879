import React from "react";
import IntroSection from "./intro/IntroSection";
import FeaturesSection from "./features/FeaturesSection";
import MainVideoSection from "./main-video/MainVideoSection";
import VideoBlockSection from "./video/VideoBlockSection";
import WonderfulSection from "./wonderful/WonderfulSection";
import WhyCodeflowSection from "./whycodeflow/WhyCodeflowSection";
import AnswersSection from "./answers/AnswersSection";
import NewsletterSection from "./newsletter/Newsletter";
import "../../styles/landing/landing.min.css";

/**
 * The Landing component serves as the main container for the landing page.
 * It aggregates various sections that make up the page, each represented
 * by a distinct component. This modular approach allows for easy maintenance
 * and scalability of the landing page.
 */
function Landing() {
  return (
    <div>
      {/* Introduction section of the landing page */}
      <IntroSection />

      {/* Section showcasing various features. The features data is passed
          as props to the FeaturesSection component. */}
      <FeaturesSection
        features={[
          {
            id: "1",
            iconHref: "https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_streamlined_setup-XNuAIGxsBV0ekO4XNA84S1t4t4Yqwo.png",
            title: "Streamlined Setup",
            description:
              "Get your GitHub Actions up and running in minutes with our user-friendly interface.",
          },
          {
            id: "2",
            iconHref: "https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_instant_deployment-NaNTXfpkTH2E3eS8BZFV52fqIitLoL.png",
            title: "Instant Deployment",
            description:
              "Deploy your GitHub Actions directly from CodeFlow with a single click.",
          },
          {
            id: "3",
            iconHref: "https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/ic_codeflow_ai-BxPBmdDsyn7yWjYYtRVAWLtWI2Wkjz.png",
            title: "Intelligent Automation with CodeFlow AI",
            description:
              "Let CodeFlow AI interpret your project needs and auto-generate efficient workflow actions.",
          },
        ]}
      />

      {/* Main video section of the page, potentially for product demonstrations or introductions */}
      <MainVideoSection />

      {/* A section for showcasing various video content */}
      <VideoBlockSection />

      {/* Section for highlighting the wonderful aspects of the product or service */}
      <WonderfulSection />

      {/* Section explaining why to choose this particular product/service */}
      <WhyCodeflowSection />

      {/* FAQ or answers section for addressing common queries */}
      <AnswersSection />

      {/* Section for newsletter subscription */}
      {/* <NewsletterSection /> */}
    </div>
  );
}

export default Landing;
