import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import FAQItem from "../../../components/FAQItem";

function AnswersSection() {
  return (
    <section className="answers">
      <div className="container">
        <SectionHeader tagline="Got Questions?" title="We Got The Answers" />
        <ul className="answers__list">
          <FAQItem title="Who can use CodeFlow?">
            CodeFlow is suitable for developers, project managers, and teams who
            use GitHub for version control and are looking to automate their
            workflows. It's user-friendly for beginners, yet powerful enough for
            advanced users.
          </FAQItem>

          <FAQItem title="Is CodeFlow free to use?">
            CodeFlow is a paid service, offering a range of features and tools
            designed for professional use. However, we are committed to
            supporting the open-source community. For open-source projects that
            meet our specified criteria, we offer CodeFlow for free. If you're
            working on an open-source project and are interested in using
            CodeFlow, please contact us to see if your project qualifies under
            our requirements. We're eager to support and collaborate with
            open-source initiatives!
          </FAQItem>

          <FAQItem title="How does CodeFlow integrate with GitHub?">
            CodeFlow integrates seamlessly with GitHub. It uses the GitHub API
            to access and manage workflows directly within your GitHub
            repositories, ensuring a secure and efficient workflow automation
            process.
          </FAQItem>

          <FAQItem title="Can I use CodeFlow for private repositories?">
            Absolutely. CodeFlow respects your privacy and security. It works
            equally well with both public and private repositories on GitHub,
            ensuring your code remains secure.
          </FAQItem>

          <FAQItem title="Will CodeFlow support more Git providers?">
            As of now, CodeFlow is designed to integrate exclusively with
            GitHub. However, we are constantly evaluating the needs of our users
            and the market. Based on user demand and feasibility, we are open to
            expanding our support to include more Git providers in the future.
            This would potentially include platforms like GitLab, Bitbucket, and
            others. We are committed to enhancing CodeFlow's capabilities and
            making it as versatile and user-friendly as possible. Keep an eye on
            our updates for any new integrations!
          </FAQItem>

          <FAQItem title="How does CodeFlow ensure the security of my data?">
            CodeFlow prioritizes security. We employ industry-standard security
            practices, including encryption and regular audits, to ensure your
            data and workflows remain protected at all times.
          </FAQItem>

          <FAQItem title="Can I collaborate with my team on CodeFlow?">
            Collaboration functionality is currently in development for
            CodeFlow. We understand the importance of teamwork, and we're
            working on integrating extensive version control features to enable
            seamless collaboration among team members. This feature is planned
            for a future update, and we're excited to bring it to our users
            soon. Stay tuned for more updates on this!
          </FAQItem>
          <li class="text-card text-card--button answers__item">
            <p class="text-card__title">
              Didn’t find what you were looking for?
            </p>
            <p class="text-card__text">
              Contact us with your questions and we will answer it for you.
            </p>
            <a
              class="button button--secondary text-card__button"
              href="mailto:hello@codeflowapp.com?subject=I have a question about CodeFlow"
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label="text"
            >
              <svg width="24" height="24" aria-hidden="true">
                <use href="#icon-mail"></use>
              </svg>
              <span>Ask a question</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default AnswersSection;
