import React from "react";
import PropTypes from "prop-types";

/**
 * WhyCodeFlowFeatureItem - A component for displaying a feature item in the 
 * 'Why CodeFlow' section.
 *
 * This component renders a feature item with a specific style defined for the 
 * 'Why CodeFlow' section. It showcases an icon, a title, and a description. 
 * It's designed to be reusable for each feature item in the section.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.iconHref - The href for the icon used in the feature item.
 * @param {string} props.title - The title of the feature item.
 * @param {string} props.description - The description text of the feature item.
 */
function WhyCodeFlowFeatureItem({ iconHref, title, description }) {
  return (
    <li className="features-item features-item--circle features-item--vertical">
      {/* Icon for the feature item */}
      <div className="features-item__icon">
        <img src={iconHref} alt="Feature Icon" width="55" height="55" />
      </div>
      {/* Content of the feature item including title and description */}
      <div className="features-item__content">
        <p className="features-item__title">{title}</p>
        <p className="features-item__description">{description}</p>
      </div>
    </li>
  );
}

// PropTypes for type checking
WhyCodeFlowFeatureItem.propTypes = {
  iconHref: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default WhyCodeFlowFeatureItem;
