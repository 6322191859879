import React, { useEffect, useState, useRef } from "react";

const DraggableAction = ({ id, children, parentRef, onPositionChange }) => {
  // State to store the current position of the element (left and top coordinates)
  const [position, setPosition] = useState({ left: 0, top: 0 });
  // State to store the initial click offset within the element during drag start
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  // State to track if the element is currently being dragged
  const [isDragging, setIsDragging] = useState(false);
  // Ref to access the DOM element for direct manipulation and measurements
  const ref = useRef(null);

  // Effect to retrieve and set the stored position from localStorage when the component mounts
  useEffect(() => {
    try {
      const storedPosition = localStorage.getItem(`action_position_${id}`);
      if (storedPosition) {
        // Parse the stored position and update the state
        setPosition(JSON.parse(storedPosition));
      } else {
        // If there's no stored position, set the initial position to center of the viewport
        const centerX = window.innerWidth / 2;
        const centerY = window.innerHeight / 2;
        // Adjust position by the element's size if it's already available
        const elementWidth = ref.current ? ref.current.offsetWidth / 2 : 0;
        const elementHeight = ref.current ? ref.current.offsetHeight / 2 : 0;
        setPosition({
          left: centerX - elementWidth,
          top: centerY - elementHeight,
        });
      }
    } catch (error) {
      // Log errors if there are issues retrieving the stored position
      console.error("Error retrieving position:", error);
    }
  }, [id]);

  // Function to handle the mouse down event
  const handleMouseDown = (e) => {
    e.preventDefault();

    const rect = ref.current.getBoundingClientRect();
    // Calculate the mouse's offset within the element at the start of the drag
    // This ensures the drag feels natural and the element doesn't jump to the mouse position
    setDragOffset({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    // Set the dragging state to true to enable movement
    setIsDragging(true);
  };

  // Function to handle the mouse move event
  const handleMouseMove = (e) => {
    // Check if dragging is in progress and a valid parentRef is provided
    if (!isDragging || !parentRef.current) return;

    // Get the bounding rectangles of the parent and draggable elements
    const parentRect = parentRef.current.getBoundingClientRect();
    const draggableRect = ref.current.getBoundingClientRect();

    // Calculate the new left and top positions for the draggable element
    const newLeft = e.clientX - dragOffset.x;
    const newTop = e.clientY - dragOffset.y;

    // Calculate the boundaries within which the draggable element can move
    const leftBoundary = parentRect.left + window.scrollX;
    const rightBoundary =
      parentRect.right + window.scrollX - draggableRect.width;
    const topBoundary = parentRect.top + window.scrollY;
    const bottomBoundary =
      parentRect.bottom + window.scrollY - draggableRect.height;

    // Constrain the new position within the calculated boundaries
    const newPos = {
      left: Math.max(leftBoundary, Math.min(newLeft, rightBoundary)),
      top: Math.max(topBoundary, Math.min(newTop, bottomBoundary)),
    };

    // Set the new position of the draggable element
    setPosition(newPos);

    // Notify parent component of position change
    if (onPositionChange) {
      onPositionChange(id, newPos);
    }
  };

  // Function to handle the mouse up event
  const handleMouseUp = () => {
    // Set dragging state to false to stop the movement
    setIsDragging(false);
    // Store the final position in localStorage
    try {
      localStorage.setItem(`action_position_${id}`, JSON.stringify(position));
    } catch (error) {
      // Log errors if there are issues saving the position
      console.error("Error saving position:", error);
    }
  };

  // Effect for adding and removing global mouse event listeners
  useEffect(() => {
    // Function to handle mouse events based on the dragging state
    const handleEvents = (e) => {
      if (isDragging) {
        handleMouseMove(e);
      }
    };

    if (isDragging) {
      // Add mouse move and mouse up listeners to the document when dragging starts
      // This allows for tracking movement anywhere on the screen
      document.addEventListener("mousemove", handleEvents);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      // Remove the listeners when not dragging
      document.removeEventListener("mousemove", handleEvents);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      // Cleanup: Remove event listeners when the component unmounts or updates
      document.removeEventListener("mousemove", handleEvents);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, dragOffset, position]);

  const style = {
    position: "absolute",
    left: `${position.left}px`,
    top: `${position.top}px`,
    cursor: isDragging ? "grabbing" : "grab",
    userSelect: isDragging ? "none" : "auto",
  };

  return (
    <div
      ref={ref}
      className="draggable-action"
      style={style}
      onMouseDown={handleMouseDown}
      aria-grabbed={isDragging}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};

export default DraggableAction;
