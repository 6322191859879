import LandingPage from "../features/landing/LandingPage";
import LicensePage from "../features/license/LicensePage";
import WhatsNewPage from "../features/whatsnew/WhatsNewPage";
import RegistrationPage from "../features/auth/RegistrationPage";
import { CodeFlow } from "../features/codeflow/CodeFlow";

const routesConfig = [
  { path: "/", component: LandingPage },
  { path: "/auth/register", component: RegistrationPage },
  { path: "/license", component: LicensePage },
  { path: "/whats-new", component: WhatsNewPage },
  { path: "/dashboard", component: CodeFlow },
];

export default routesConfig;