import React from 'react';
import { hexToRGBA } from "../utils/ColorUtil";

const Divider = ({
  width = 1,
  orientation = 'horizontal',
  color = '#DFE4EA',
  colorOpacity,
  dividerStyle,
  id
}) => {
  const dividerStyles = {
    width: orientation === 'horizontal' ? '100%' : width,
    height: orientation === 'vertical' ? '100%' : width,
    backgroundColor: hexToRGBA(color, colorOpacity),
    ...dividerStyle,
  };

  return <div id={id} className='divider' style={dividerStyles} />;
};

export default Divider;
