import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const UserProfileContext = createContext(null);

export const UserProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(
        `http://localhost:3000/api/user`,
        { withCredentials: true }
      );

      setUserProfile(response.data);
    } catch (error) {
      console.error("Error fetching workflow content:", error);
    }
  };

  useEffect(() => {
    const loadProfile = async () => {
        fetchUserProfile();
    };

    loadProfile();
  }, []);

  return (
    <UserProfileContext.Provider value={userProfile}>
      {children}
    </UserProfileContext.Provider>
  );
};
