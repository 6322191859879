import React from 'react';
import PropTypes from 'prop-types';

function UpdatesItem({ version, description, tags }) {
  return (
    <li className="text-card updates__item">
      <p className="text-card__title">{version}</p>
      <p className="text-card__text">{description}</p>
      <div className="text-card__tags">
        {tags.map((tag, index) => (
          <span key={index} className="tag tag--accent">
            <svg width="20" height="20" aria-hidden="true">
              <use href="#icon-plus-circle"></use>
            </svg>
            <span>{tag}</span>
          </span>
        ))}
      </div>
    </li>
  );
}

UpdatesItem.propTypes = {
  version: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UpdatesItem;