import React from 'react';
import PropTypes from 'prop-types';

function LicenseItem({ title, items, contentType }) {
  const renderContent = () => {
    switch (contentType) {
      case 'list':
        return (
          <ul className="content__list">
            {items.map((item, index) => (
              <li key={index}>• {item}</li>
            ))}
          </ul>
        );
      case 'text':
        return (
          <div className="content__text">
            {items.map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="license__item">
      <h2 className="title title--h4">{title}</h2>
      {renderContent()}
    </div>
  );
}

LicenseItem.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  contentType: PropTypes.oneOf(['list', 'text']).isRequired,
};

export default LicenseItem;