import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import { useRemoteConfig } from "../../../providers/RemoteConfigProvider";

/**
 * The WonderfulSection component is a part of the webpage showcasing an
 * aesthetically pleasing design section. It features a parallax image to
 * create a sense of depth and a SectionHeader for consistent presentation.
 */
function WonderfulSection() {
  const config = useRemoteConfig();

  return (
    <section className="wonderful-section" data-parallax-container="">
      <div className="container">
        {/* SectionHeader component used for displaying the header of the section.
            It receives a tagline, a title, and a description as props. */}
        <SectionHeader
          tagline="CodeFlow"
          title="Streamline Workflows with Precision"
          description="Embrace the simplicity of CodeFlow — where every action is a step towards perfection."
        />

        {/* Parallax image container for visual effect */}
        <div className="wonderful-section__image-parallax">
          <div className="image-parallax" data-parallax-image="" data-speed="8">
            <picture>
              {/* Responsive image sources for different screen sizes and resolutions */}
              <source media="(max-width: 599px)" srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_precision-WTmRl7wVvrgxJBQulfyXyahZozQaV3.png" />
              <source srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_precision-WTmRl7wVvrgxJBQulfyXyahZozQaV3.png" />
              <source media="(max-width: 599px)" srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_precision-WTmRl7wVvrgxJBQulfyXyahZozQaV3.png" />
              <img
                src="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_precision-WTmRl7wVvrgxJBQulfyXyahZozQaV3.png"
                srcSet="https://qhr1o0jbubb16tpa.public.blob.vercel-storage.com/Landing/hero_precision-WTmRl7wVvrgxJBQulfyXyahZozQaV3.png"
                width="1520"
                height="640"
                alt="image components"
              />
            </picture>
          </div>
        </div>

        {/* Call-to-action button linking to an external resource */}
        {config.isProductionReady ? (
          <a
            className="button button--secondary wonderful-section__button"
            href="/auth/register"
            rel="nofollow noopener noreferrer"
            aria-label="Get Started"
          >
            <span>Get Started</span>
            {/* Icons within the button for visual enhancement */}
            <svg width="24" height="24" aria-hidden="true">
              <use xlinkHref="#icon-figma"></use>
            </svg>
            <svg width="24" height="24" aria-hidden="true">
              <use xlinkHref="#icon-arrow-right-up"></use>
            </svg>
          </a>
        ) : null}
      </div>
    </section>
  );
}

export default WonderfulSection;
