import React from "react";
import PropTypes from "prop-types";

const FooterNavItem = ({ text, href, isLink }) => {
  return (
    <li className="footer-nav__item">
      {isLink ? (
        <a
          className="footer-nav__link link"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </a>
      ) : (
        <span className="footer-nav__link link footer-nav__link--soon">
          {text}
        </span>
      )}
    </li>
  );
};

FooterNavItem.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  isLink: PropTypes.bool,
};

FooterNavItem.defaultProps = {
  isLink: true,
  href: "#",
};

export default FooterNavItem;