import React, { useState } from "react";
import PropTypes from "prop-types";
import GithubIcon from "./GithubIcon";
import {
  darkStyle,
  lightStyle,
  disabledStyle,
  hoverStyle,
} from "../styles/GithubButton";

const GithubButton = ({
  label = "Sign in with Github",
  disabled = false,
  tabIndex = 0,
  onClick = () => {},
  type = "dark",
  style,
  ...otherProps
}) => {
  const [hovered, setHovered] = useState(false);

  const getStyle = (propStyles) => {
    const baseStyle = type === "dark" ? darkStyle : lightStyle;
    if (hovered) {
      return { ...baseStyle, ...hoverStyle, ...propStyles };
    }
    if (disabled) {
      return { ...baseStyle, ...disabledStyle, ...propStyles };
    }
    return { ...baseStyle, ...propStyles };
  };

  const mouseOver = () => {
    if (!disabled) {
      setHovered(true);
    }
  };

  const mouseOut = () => {
    if (!disabled) {
      setHovered(false);
    }
  };

  const click = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <div
      {...otherProps}
      role="button"
      onClick={click}
      style={getStyle(style)}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <GithubIcon {...otherProps} />
      <span>{label}</span>
    </div>
  );
};

GithubButton.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["light", "dark"]),
  style: PropTypes.object,
};

export default GithubButton;
