import React from "react";
import { ThemeProvider, BaseStyles } from "@primer/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Footer from "./features/footer/footer";
import Header from "./features/header/header";
import routesConfig from "./configs/routesConfig";
import CodeFlowHeader from "./features/codeflow/CodeFlowHeader";
import { UserProfileProvider } from "./providers/UserProfileProvider";
import { UserNotificationsProvider } from "./providers/UserNotificationsProvider";
import { RemoteConfigProvider } from "./providers/RemoteConfigProvider";
import { SpeedInsights } from "@vercel/speed-insights/react"

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <BaseStyles>
          <Router>
            <RemoteConfigProvider>
              <UserProfileProvider>
                <UserNotificationsProvider>
                  <Layout />
                </UserNotificationsProvider>
              </UserProfileProvider>
            </RemoteConfigProvider>
          </Router>
        </BaseStyles>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

const Layout = () => {
  const location = useLocation();
  const showCodeflowHeader = location.pathname === "/dashboard";

  return (
    <>
      {showCodeflowHeader ? (
        <CodeFlowHeader />
      ) : (
        <header className="header">
          <Header />
        </header>
      )}
      <main>
        <Routes>
          {routesConfig.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Routes>
      </main>
      <footer className="footer">
        <Footer />
      </footer>
      <SpeedInsights />
    </>
  );
};

export default App;
