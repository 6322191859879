import { useContext } from 'react';
import { UserNotificationsContext } from '../providers/UserNotificationsProvider';

export const useUserNotifications = () => {
  const context = useContext(UserNotificationsContext);

  if (context === undefined) {
    throw new Error('useUserNotifications must be used within a UserNotificationsProvider');
  }

  return context;
};