import React from "react";
import { ActionList } from "@primer/react";

export const Notifications = ({ notifications }) => {
  const currentDate = new Date();
  const oneWeekAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const oneMonthAgo = new Date(
    currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
  );

  const groupByDate = (date) => {
    if (date >= oneWeekAgo) {
      return "One Week Ago";
    } else if (date >= oneMonthAgo) {
      return "One Month Ago";
    } else {
      return "Long Ago";
    }
  };

  const groupedNotifications = notifications.reduce((groups, notification) => {
    const group = groupByDate(notification.lastUpdate);
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(notification);
    return groups;
  }, {});

  return (
    <ActionList showDividers style={{ overflowY: "auto", maxHeight: "100%" }}>
      {Object.entries(groupedNotifications).map(([group, notifications]) => (
        <ActionList.Group key={group} title={group} variant="filled">
          {notifications.map((notification) => (
            <ActionList.Item key={notification.id}>
              {notification.notificationInformation.title}
              <ActionList.Description variant="block">
                {notification.notificationInformation.type}
              </ActionList.Description>
            </ActionList.Item>
          ))}
        </ActionList.Group>
      ))}
    </ActionList>
  );
};
