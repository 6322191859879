import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Sidebar from "../repositories/Sidebar";
import PropertiesBar from "../properties/propertiesbar";
import CodeFlowContent from "./CodeFlowContent";
import "../../styles/core/Codeflow.css";
import MarketplaceSidebar from "../marketplace/MarketplaceSidebar";

export const CodeFlow = () => {
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [isPropertiesBarVisible, setPropertiesBarVisible] = useState(false);
  const [selectedGithubWorkflow, setSelectedGithubWorkflow] = useState(null);
  const [selectedGithubActionStep, setSelectedGithubActionStep] = useState(
    null
  );
  const [selectedJobId, setSelectedJobId] = useState(
    null
  );
  const [showActionDetails, setShowActionDetails] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [actionInfo, setActionInfo] = useState([]);
  const [actionContent, setActionContent] = useState("");
  const [actionStepContent, setActionStepContent] = useState({});
  const actionInfoCache = useRef({});

  useEffect(() => {
    const fetchRepositoryWorkflows = async () => {
      if (selectedRepo) {
        try {
          const response = await axios.get(
            `http://localhost:3000/api/repositories/workflows/${selectedRepo.author}/${selectedRepo.name}`,
            {
              withCredentials: true,
            }
          );
          setWorkflows(response.data);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    fetchRepositoryWorkflows();
  }, [selectedRepo]);

  useEffect(() => {
    let didCancel = false;

    const fetchRepositoryWorkflowInfo = async () => {
      if (!selectedRepo || !selectedGithubWorkflow) return;

      const cacheKey = `${selectedRepo.author}/${selectedRepo.name}/${selectedGithubWorkflow}`;

      // Check if data is in cache
      if (actionInfoCache.current[cacheKey]) {
        setActionInfo(actionInfoCache.current[cacheKey]);
        return;
      }

      try {
        const response = await axios.get(
          `http://localhost:3000/api/repositories/workflows/content/${selectedRepo.author}/${selectedRepo.name}/${selectedGithubWorkflow}/info`,
          { withCredentials: true }
        );

        if (!didCancel) {
          actionInfoCache.current[cacheKey] = response.data;
          setActionInfo(response.data);
        }
      } catch (error) {
        console.error("Error fetching workflow content:", error);
      }
    };

    fetchRepositoryWorkflowInfo();

    return () => {
      didCancel = true;
    };
  }, [selectedRepo, selectedGithubWorkflow]);

  const handleSelectRepo = (repo) => {
    setSelectedRepo(repo);
    setWorkflows([]);
  };

  const togglePropertiesBar = () => {
    setPropertiesBarVisible(!isPropertiesBarVisible);
  };

  const handleGitHubWorkflowClick = (actionName) => {
    if (selectedGithubWorkflow === actionName) {
      togglePropertiesBar();
      setSelectedGithubWorkflow(null);
    } else {
      setSelectedGithubWorkflow(actionName);
      if (!isPropertiesBarVisible) {
        setPropertiesBarVisible(true);
      }
    }
  };

  const handleGithubActionStepClick = (stepId) => {
    if (selectedGithubActionStep === stepId) {
      togglePropertiesBar();
      setSelectedGithubActionStep(null);
    } else {
      setSelectedGithubActionStep(stepId);
      if (!isPropertiesBarVisible) {
        setPropertiesBarVisible(true);
      }
    }
  };

  const updateShowActionDetails = (value) => {
    setShowActionDetails(value);
  };

  const handleActionContentUpdate = (content) => {
    setActionContent(content);
  };

  const handleActionStepData = (data) => {
    setActionStepContent(data);
  };

  const handleSelectedJobId = (jobId) => {
    setSelectedJobId(jobId)
  }

  return (
    <div className="codeflow">
      {showActionDetails ? (
        <MarketplaceSidebar />
      ) : (
        <Sidebar
          selectedRepo={selectedRepo}
          handleSelectRepo={handleSelectRepo}
        />
      )}
      <CodeFlowContent
        isPropertiesBarVisible={isPropertiesBarVisible}
        selectedRepo={selectedRepo}
        selectedGithubWorkflow={selectedGithubWorkflow}
        selectedGithubActionStep={selectedGithubActionStep}
        selectedJobId={handleSelectedJobId}
        showActionDetails={showActionDetails}
        handleGitHubWorkflowClick={handleGitHubWorkflowClick}
        handleGitHubActionStepClick={handleGithubActionStepClick}
        handleOpenAction={updateShowActionDetails}
        onActionContentUpdate={handleActionContentUpdate}
        workflows={workflows}
        selectedActionStepData={handleActionStepData}
      />
      {isPropertiesBarVisible && (
        <PropertiesBar
          actionInfo={actionInfo}
          action_content={actionContent}
          actionStepContent={actionStepContent}
          selectedJobId={selectedJobId}
          selectedStepId={selectedGithubActionStep}
        />
      )}
    </div>
  );
};

export default CodeFlow;
