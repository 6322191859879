import React from "react";

const GAJobInfoItem = ({
  name,
  description,
  children,
  orientation = "vertical",
}) => {
  const childrenContainerStyle =
    orientation === "horizontal"
      ? { display: "flex", flexDirection: "row" }
      : { display: "flex", flexDirection: "column" };

  return (
    <div className="job-info-item">
      <div className="item-name">{name}</div>
      {description ? (
        <div className="item-description">{description}</div>
      ) : (
        <div style={childrenContainerStyle}>{children}</div>
      )}
    </div>
  );
};

export default GAJobInfoItem;
