import React from "react";
import FooterNavItem from "./FooterNavItem";
import "../../styles/landing/landing.min.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div class="container">
      <div class="footer__content">
        <span class="footer__logo logo" role="img">
          <a class="feedback__mail">CodeFlow</a>
          <span>by Tihan-Nico Paxton</span>
        </span>
        <div class="footer-nav">
          <div class="footer-nav__col">
            <p class="footer-nav__title">CodeFlow</p>
            <ul class="footer-nav__list">
              <FooterNavItem text="Updates" href="/whats-new" isLink={false} />
              <FooterNavItem
                text="Usage Guide"
                href="guide.html"
                isLink={false}
              />
              <FooterNavItem text="License" href="/license" />
            </ul>
          </div>
          <div class="footer-nav__col">
            <p class="footer-nav__title">Feature Guide</p>
            <ul class="footer-nav__list">
              <FooterNavItem
                text="Debugger"
                href="/guide#debugger"
                isLink={false}
              />
              <FooterNavItem
                text="CodeFlow AI"
                href="/guide#codeflow-ai"
                isLink={false}
              />
              <FooterNavItem
                text="Drag and Drop"
                href="/guide#drag-and-drop"
                isLink={false}
              />
            </ul>
          </div>
          <div class="footer-nav__col">
            <p class="footer-nav__title">Socials</p>
            <ul class="footer-nav__list">
              <FooterNavItem text="Instagram" href="https://instagram.com" />
              <FooterNavItem text="Discord" href="https://discord.com" />
              <FooterNavItem text="Twitter" href="https://twitter.com" />
            </ul>
          </div>
        </div>
        <div class="footer__feedback feedback">
          <div class="feedback__title">Got any questions?</div>
          <a class="feedback__mail" href="mailto:hello@codeflowapp.com">
            support@codeflowapp.com
          </a>
          <div class="feedback__notice">
            Go ahead, let your thoughts fly! We're all ears and speak your
            language. Your feedback isn't just welcome, it's eagerly awaited!
          </div>
        </div>
      </div>
      <div class="footer__copyright">
        <p>©{currentYear}. All rights reserved</p>
      </div>
    </div>
  );
}

export default Footer;
