import React from "react";
import SectionHeader from "../../components/SectionHeader";
import LicenseItem from "../../components/LicenseItem";

function LicensePage() {
  return (
    <section class="license">
      <div class="container">
        <SectionHeader tagline="Where Legalese Meets a Chuckle: Not Your Typical Fine Print!" title="License" />
        <div class="license__body content">
          <div class="license__intro">
            <div class="content__text">
              <p>
                This Software License Agreement ("Agreement") is a binding legal
                agreement between the individual or legal entity ("Licensee")
                acquiring the software rights and CodeFlow
                ("Licensor"). By installing, copying, or otherwise using
                CodeFlow software (the "Software"), including its
                user interface, drag-and-drop functionalities, and real-time
                debugging features, you agree to be bound by the terms of this
                Agreement.
              </p>
            </div>
          </div>
          <LicenseItem
            title="Grant of License:"
            items={[
              "The Licensor grants the Licensee a non-exclusive, worldwide, perpetual license to use the Item.",
              "The Software is designed to facilitate workflow automation for GitHub Actions, intended to enhance productivity and efficiency in software development processes.",
            ]}
            contentType="list"
          />

          <LicenseItem
            title="Permitted Uses"
            items={[
              "Licensee is authorized to use the Software's features, including its intuitive interface, drag-and-drop functionality, and real-time debugging, for creating and managing automated workflows.",
              "The Software may be used for personal, commercial, or educational purposes, provided such use complies with this Agreement's terms.",
            ]}
            contentType="list"
          />

          <LicenseItem
            title="Restrictions"
            items={[
              "Licensee shall not modify, distribute, sublicense, or create derivative works of the Software without the prior written consent of the Licensor.",
              "The Software, or any part thereof, may not be sold, rented, or leased as a standalone product.",
              "Reverse engineering, decompiling, or disassembling the Software, except to the extent permitted by applicable law, is prohibited.",
            ]}
            contentType="list"
          />

          <LicenseItem
            title="Intellectual Property"
            items={[
              "All intellectual property rights in the CodeFlow software, including but not limited to its user interface, functionality, and underlying technology, are owned by the Licensor, except as explicitly stated herein.",
              "Portions of the Software's user interface are built using GitHub's Primer CSS. Primer CSS is the intellectual property of GitHub, Inc., and is used under their terms and conditions. The use of GitHub's Primer CSS within CodeFlow does not imply any affiliation with or endorsement by GitHub, Inc.",
              "The Licensee acknowledges that the Software may incorporate intellectual property owned by third parties such as GitHub, Inc. The Licensee agrees to respect the intellectual property rights of these third parties and understands that their use of the Software is subject to compliance with the respective terms and conditions of such third parties.",
              "This Agreement does not transfer to the Licensee any rights, title, or interest in or to such third-party intellectual property, except for the limited rights to use such third-party intellectual property as are necessary to use the Software in accordance with this Agreement.",
              "If the Licensee becomes aware of any claims or potential infringements concerning third-party intellectual property rights in relation to the Software, the Licensee agrees to promptly notify the Licensor of such claims or potential infringements.",
            ]}
            contentType="list"
          />

          <LicenseItem
            title="Disclaimer of Warranties"
            items={[
              'The Software is provided "as is," without warranty of any kind, either express or implied. The Licensor does not warrant the Software\'s performance or the results obtained from its use.',
            ]}
            contentType="text"
          />

          <LicenseItem
            title="Limitation of Liability"
            items={[
              "In no event shall the Licensor be liable for any damages arising from the use or inability to use the Software.",
            ]}
            contentType="text"
          />

          <LicenseItem
            title="Amendments and Termination"
            items={[
              "This Agreement is subject to change by the Licensor without prior notice. Continued use of the Software after any such changes constitutes your consent to such changes.",
              "The Licensee's rights under this Agreement will terminate automatically without notice if they fail to comply with any of its terms.",
            ]}
            contentType="list"
          />
          <LicenseItem
            title="Governing Law"
            items={[
              "This Agreement shall be governed by the laws of South-Africa, excluding its conflict of law principles.",
            ]}
            contentType="text"
          />
          <LicenseItem
            title="Acceptance"
            items={[
              "By installing, copying, or otherwise using the Software, the Licensee acknowledges that they have read this Agreement, understand its terms, and agree to be bound by them.",
            ]}
            contentType="text"
          />
        </div>
      </div>
    </section>
  );
}

export default LicensePage;
