import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../styles/core/GitHubMarketplace/MarketplaceSidebar.css";
import "react-resizable/css/styles.css";
import SidebarLoading from "../../components/core/SidebarLoading";
import { MarketplaceItem } from "../../components/core/GitHubMarketplace/MarketplaceActionItem";
import Divider from "../../components/Divider";

const MarketplaceSidebar = () => {
  const [marketplaceActions, setMarketplaceActions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let didCancel = false;

    const fetchMarketplace = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `http://localhost:3000/api/marketplace?category=api-management&type=actions`
        );

        if (!didCancel) {
          console.log(response.data);
          setMarketplaceActions(response.data);
        }
      } catch (error) {
        console.error("Error fetching workflow content:", error);
      }
      setIsLoading(false);
    };

    fetchMarketplace();

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <div className="codeflow__sidebar">
      <div className="marketplace-header">
        <h5>Marketplace</h5>
      </div>
      <Divider />
      <div className="marketplace-list">
        {isLoading && marketplaceActions.length === 0 ? (
          <SidebarLoading />
        ) : (
          marketplaceActions.map((action, index) => {
            return (
              <MarketplaceItem
                imageUrl={action.svg}
                author={action.author}
                actionName={action.title}
                actionDescription={action.description}
              />
            );
          })
        )}
        {isLoading && marketplaceActions.length > 0 && <SidebarLoading />}
      </div>
    </div>
  );
};

export default MarketplaceSidebar;
