import React from "react";
import PropTypes from "prop-types";

const MainNavItem = ({ text, href, className }) => {
  return (
    <li className="main-nav__item">
      <a
        className={`main-nav__link link ${className}`}
        href={href}
      >
        {text}
      </a>
    </li>
  );
};

MainNavItem.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
};

MainNavItem.defaultProps = {
  className: "",
};

export default MainNavItem;