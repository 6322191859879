import React from "react";
import SectionHeader from "../../../components/SectionHeader";
import VideoPlayerItem from "../../../components/VideoPlayerItem";

function VideoBlockSection() {
  return (
    <div className="video-block">
      <section className="section-video">
        <div className="container">
          <div className="section-video__content">
            <SectionHeader
              tagline="Ready to interact"
              title="Engineer Exceptional Workflows"
              description="Unlock the power of automation with CodeFlow. Design, test, and deploy GitHub Actions with interactive ease — experience the workflow revolution."
            />
            <VideoPlayerItem videoSrc="video/square1.mp4" />
          </div>
        </div>
      </section>
      <section className="section-video section-video--reverse">
        <div className="container">
          <div className="section-video__content">
            <SectionHeader
              tagline="Customize, Commit, Conquer"
              title="Effortless Action Customization"
              description="Refine your GitHub workflows with just a few clicks. Apply changes across multiple actions simultaneously—efficiency has never been this simple."
            />
            <VideoPlayerItem videoSrc="video/square1.mp4" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default VideoBlockSection;
