import React from "react";
import { ShimmerCategoryItem } from "react-shimmer-effects"

const SidebarLoading = () => {
    return (
        <ShimmerCategoryItem
          hasImage
          imageType="circular"
          imageWidth={50}
          imageHeight={50}
          title
          text
        />
    )
}

export default SidebarLoading;