import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@primer/react";
import "../../../styles/core/GitHubActions/GitHubActionItem.css";

const GitHubActionItem = ({
  action_name,
  onActionClick,
  onOpenAction,
  isSelected,
  onActionInfoUpdate,
  repo,
  workflow,
}) => {
  const [actionYaml, setActionYaml] = useState(null);
  const itemClass = isSelected ? "gha-item selected" : "gha-item";

  useEffect(() => {
    let didCancel = false;

    const fetchRepositoryWorkflows = async () => {
      if (!repo || !workflow) return;

      try {
        const response = await axios.get(
          `http://localhost:3000/api/repositories/workflows/content/${repo.author}/${repo.name}/${workflow.name}`,
          {
            withCredentials: true,
          }
        );

        // Only update state if we did not cancel the request
        if (!didCancel) {
          setActionYaml(response.data);
        }
      } catch (error) {
        console.error("Error fetching workflow content:", error);
      }
    };

    fetchRepositoryWorkflows();

    return () => {
      didCancel = true;
    };
  }, [repo, workflow]);

  return (
    <div className={itemClass} onClick={() => onActionClick(action_name)}>
      <div className="gha-item-header">
        <a className="gha-item-header-text">{action_name}</a>
      </div>
      {isSelected && (
        <Button
          className="gha-primary-action"
          variant="primary"
          size="large"
          onClick={onOpenAction}
        >
          Open Action
        </Button>
      )}
    </div>
  );
};

// Set default props in case they are not provided
GitHubActionItem.defaultProps = {
  action_name: "~Unknown Action",
  repo: {},
  workflow: {},
};

export default React.memo(GitHubActionItem);
