// RemoteConfigContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { remoteConfig } from "../configs/firebaseConfig.js";
import { getBoolean } from "firebase/remote-config";

// Create a Context
const RemoteConfigContext = createContext();

export const useRemoteConfig = () => useContext(RemoteConfigContext);

// Provider Component
export const RemoteConfigProvider = ({ children }) => {
  const [configValues, setConfigValues] = useState({});

  useEffect(() => {
    // Function to fetch and set config values
    const fetchConfig = async () => {
      const isProductionReady = getBoolean(remoteConfig, "isProductionReady");
      setConfigValues((currentValues) => ({ ...currentValues, isProductionReady }));
    };

    fetchConfig();
  }, []);

  return (
    <RemoteConfigContext.Provider value={configValues}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
