import React from "react";
import PropTypes from "prop-types";
import FeatureItem from "../../../components/FeatureItem";

function FeaturesSection({ features }) {
  return (
    <section className="features">
      <h2 className="visually-hidden">Features</h2>
      <div className="container">
        <ul className="features-list features-list--counter features__grid">
          {features.map((feature) => (
            <FeatureItem
              key={feature.id}
              iconHref={feature.iconHref}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </ul>
      </div>
    </section>
  );
}

FeaturesSection.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      iconHref: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FeaturesSection;
