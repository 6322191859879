import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const UserNotificationsContext = createContext();

export const UserNotificationsProvider = ({ children }) => {
  const [userNotifications, setUserNotifications] = useState({
    notifications: [],
    repositoriesWithoutWebhooks: [],
  });
  const [error, setError] = useState(null);

  const fetchUserNotifications = async () => {
    try {
      const webhookResponse = await axios.get(
        `http://localhost:3000/api/repositories/without-webhooks`,
        { withCredentials: true }
      );

      const notificationsResponse = await axios.get(
        `http://localhost:3000/api/user/notifications`,
        { withCredentials: true }
      );

      // Assuming both responses are essential and should be non-null
      if (webhookResponse.data && notificationsResponse.data) {
        setUserNotifications({
          notifications: notificationsResponse.data,
          repositoriesWithoutWebhooks: webhookResponse.data,
        });
      }
    } catch (error) {
      console.error("Error fetching user notifications", error);
      setError(error); // Set error state
    }
  };

  useEffect(() => {
    fetchUserNotifications();
  }, []);

  return (
    <UserNotificationsContext.Provider value={{ userNotifications, error }}>
      {children}
    </UserNotificationsContext.Provider>
  );
};
